(function ($, generic, site) {
  var titleTemplate;
  var titleVars = {};
  var $titles = $();
  var titleClass = 'signin-block__authed__nav__title';
  var rClass = titleClass + '--rendered';

  function _renderTitles() {
    if(titleTemplate !== undefined) {
      $titles.html(Mustache.render(titleTemplate, titleVars)).addClass(rClass);
    }
  }

  Drupal.behaviors.signinBlock = {
    attach: function (context) {
      var $blocks = $('.js-signin-block', context);

      $titles = $titles.add($('.' + titleClass, $blocks));
      var firstName = site.userInfoCookie.getValue('first_name');
      var $forgotPassword = $('.js-forgot-password', $blocks);

      if (firstName) {
        titleVars.first_name = firstName;
      }
      if (!titleTemplate) {
        titleTemplate = $titles.first().html();
      }
      _renderTitles();

      $blocks.each(function () {
        var $block = $(this);
        var $toggleTrigger = $('.js-signin-block-toggle', this);
        var $registerForm = $('.signin-block__form--register', this);
        var $toggleRegisterPass = $('.signin-block__toggle-password__option', $registerForm);
        var $registerPass = $('.signin-block__field--password', $registerForm);
        var showPass = false;

        function togglePassword() {
          showPass = !showPass;
          $registerPass.get(0).type = showPass ? 'text' : 'password';
          $block.toggleClass('signin-block--show-password', showPass);
        }

        $toggleRegisterPass.on('click', function (event) {
          event.preventDefault();
          togglePassword();
        });

        $registerForm.on('submit', function () {
          if (showPass) {
            togglePassword();
          }
        });

        $toggleTrigger.on('click', function (event) {
          event.preventDefault();
          $block.toggleClass('signin-block--register');
        });
      });

      if (generic.cookie('USER_RECOGNIZED')) {
        $blocks.addClass('signin-block--signin');
      }

      if ($forgotPassword.length > 0) {
        site.signin.forgotPassword({
          resetPassword: true,
          errorListNode: ' ',
          forgotPasswordLink: $forgotPassword
        });
      }
    }
  };

  $(document).on('user.loaded', function (event, val) {
    // This is brittle but there's no other way to tell from the data we get.
    if (!$titles.length) {
      $titles = $('.' + titleClass);
    }
    if (!titleTemplate) {
      titleTemplate = $titles.first().html();
    }
    titleVars.first_name = val.first_name;

    // If user is ever signed in, permanently store a "USER_REC" cookie. This
    // only expires once a year.
    if (val.signed_in) {
      generic.cookie('USER_RECOGNIZED', 1, {
        path: '/',
        expires: 365
      });
    }

    _renderTitles();
  });

  $(document).on('change', '.js-signin-block__field--cpf', function (event, val) {
    event.preventDefault();
    $('.js-gnav-util__content .js-fed-doc-number').val($(this).val());
  });
  $(document).on('change', 'form#form--registration_short .js-pc_email_address-field', function (event) {
    event.preventDefault();
    $('.js-femail-address', 'form#form--registration_short').val($(this).val());
  });
})(jQuery, window.generic || {}, window.site || {});
